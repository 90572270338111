import { Helmet } from "react-helmet"
export default Object.freeze({
  // ====== PAGE LINKS ======
  HOME: "/",
  ABOUT: "/about",
  SERVICES: "/services",
  CONTENT_MARKETING: "/services/content-marketing",
  BLOGS: "/blogs",
  SERVICE_DETAILS: "/service/details",
  SERVICE_DETAILS_SLUGS: [
    {
      slug: "business-strategy",
      id: 1,
      meta: () => (
        <Helmet>
          <title>Ikana Business Solutions: Business Strategy for Startups</title>
          <meta name="description" content="Take charge of the direction and position of your business effectively with intelligent and intuitive business strategy and plan." />
          <link rel="canonical" href="https://ikana.io/service/business-strategy" />
        </Helmet>
      )
    },
    {
      slug: "marketing-strategy",
      id: 2,
      meta: () => (
        <Helmet>
          <title>Ikana Business Solutions: Marketing Strategy for Businesses</title>
          <meta name="description" content="Achieve your marketing objectives while using resources- both human and otherwise, efficiently while simultaneously getting better qualified leads." />
          <link rel="canonical" href="https://ikana.io/service/marketing-strategy" />
        </Helmet>
      )
    },
    {
      slug: "content-production",
      id: 3,
      meta: () => (
        <Helmet>
          <title>Ikana Business Solutions: Content Production Service</title>
          <meta name="description" content="Leverage content to convey the right message to the right people. Create relevant and impactful content that helps you achieve your strategic goals." />
          <link rel="canonical" href="https://ikana.io/service/content-production" />
        </Helmet>
      )
    },
    {
      slug: "content-marketing",
      id: 4,
      meta: () => (
        <Helmet>
          <title>360 Content-based marketing by Ikana Business Solutions</title>
          <meta name="description" content="Create, promote and publish your quality content for consumption by your target audience. We can help boost your brand awareness and create loyal customers." />
          <link rel="canonical" href="https://ikana.io/service/content-marketing" />
        </Helmet>
      )
    },
    {
      slug: "sales-strategy",
      id: 5,
      meta: () => (
        <Helmet>
          <title>Sales Strategy by Ikana Business Solutions</title>
          <meta name="description" content="Bridge the gap between the potential customers and your business with Sales Strategy. Develop the sales strategy that is driven by your customer's behaviour." />
          <link rel="canonical" href="https://ikana.io/service/sales-strategy" />
        </Helmet>
      )
    },
    {
      slug: "ui-ux-design",
      id: 6,
      meta: () => (
        <Helmet>
          <title>UI/UX Design Services by Ikana Business Solutions</title>
          <meta name="description" content="Develop intuitive and interactive UI/UX designs for your app. Get a fully functional UI without coding a single line and save time." />
          <link rel="canonical" href="https://ikana.io/service/ui-ux-design" />
        </Helmet>
      )
    },
    {
      slug: "branding",
      id: 7,
      meta: () => (
        <Helmet>
          <title>Branding Services By Ikana Business Solutions</title>
          <meta name="description" content="Build a brand identity that not only resonates with the vision of your business but also makes it unmissable. Together, we mould your business into a brand" />
          <link rel="canonical" href="https://ikana.io/service/branding" />
        </Helmet>
      )
    },
  ],
  PROJECTS: "/projects/all",
  PROJECT_DETAILS: "/project/details",
  PROJECT_DETAILS_SLUGS: [
    {
      slug: "unary-team-business-strategy",
      id: 17,
      meta: () => (
        <Helmet>
          <title>Unary Team Business Strategy by Ikana Business Solution</title>
          <meta name="description" content="We worked with our client, Unary Team to find areas of opportunities in the Middle-east market and to chalk out the blueprint of essential business processes." />
          <link rel="canonical" href="https://ikana.io/project/unary-team-business-strategy" />
        </Helmet>
      )
    },
    {
      slug: "pickit-marketing-strategy",
      id: 15,
      meta: () => (
        <Helmet>
          <title>Pickit Marketing Strategy By Ikana Business Solution</title>
          <meta name="description" content="In this project, we were tasked with launching the marketing campaign of Pickit. We worked to create a space for Pickit and pave the way for user onboarding." />
          <link rel="canonical" href="https://ikana.io/project/pickit-marketing-strategy" />
        </Helmet>
      )
    },
    {
      slug: "ihelp-marketing-strategy",
      id: 16,
      meta: () => (
        <Helmet>
          <title>Ihelp Marketing Strategy by Ikana Business Solution</title>
          <meta name="description" content="In this interesting project, our Team was assigned the task of creating awareness and launching the marketing campaign of a new app - Ihelp." />
          <link rel="canonical" href="https://ikana.io/project/ihelp-marketing-strategy" />
        </Helmet>
      )
    },
    {
      slug: "ihelp-content-development-strategy",
      id: 19,
      meta: () => (
        <Helmet>
          <title>Ihelp Content Development Strategy by Ikana Business Solution</title>
          <meta name="description" content="In this project we were responsible for setting up the client’s social media accounts and creation of content from scratch that meets the brand guidelines." />
          <link rel="canonical" href="https://ikana.io/project/ihelp-content-development-strategy" />
        </Helmet>
      )
    },
    {
      slug: "explainer-video-replica",
      id: 20,
      meta: () => (
        <Helmet>
          <title>Explainer Video for Replica by Ikana</title>
          <meta name="description" content="We created an explainer video for Replica, a virtual trading platform, that met the brand guidelines. The design of the video followed the concept of minimalism." />
          <link rel="canonical" href="https://ikana.io/project/explainer-video-replica" />
        </Helmet>
      )
    },
    {
      slug: "pickit-social-media-management",
      id: 24,
      meta: () => (
        <Helmet>
          <title>Pickit Social Media Management & Content Production by Ikana</title>
          <meta name="description" content="In this project we were responsible for setting up the client’s social media accounts and creation of content from scratch that meets the brand guidelines." />
          <link rel="canonical" href="https://ikana.io/project/pickit-social-media-management" />
        </Helmet>
      )
    },
    {
      slug: "ihelp-social-media-management",
      id: 25,
      meta: () => (
        <Helmet>
          <title>Ihelp Social Media Management & Content Creation by Ikana</title>
          <meta name="description" content="In this project we were responsible for setting up the client’s social media accounts and creation of content from scratch that meets the brand guidelines." />
          <link rel="canonical" href="https://ikana.io/project/ihelp-social-media-management" />
        </Helmet>
      )
    },
    {
      slug: "replica-video-creation",
      id: 26,
      meta: () => (
        <Helmet>
          <title>Video Creation for Replica by Ikana</title>
          <meta name="description" content="In this project we were responsible for creating an explainer video for Replica, a virtual trading platform, that met the brand guidelines." />
          <link rel="canonical" href="https://ikana.io/project/replica-video-creation" />
        </Helmet>
      )
    },
    {
      slug: "unary-team-sales-strategy",
      id: 18,
      meta: () => (
        <Helmet>
          <title>Unary Sales strategy By Ikana Business Solution</title>
          <meta name="description" content="We analysed the status of the company’s existing sales, designing new sales strategies and eventually bringing better business opportunities in the middle east" />
          <link rel="canonical" href="https://ikana.io/project/unary-team-sales-strategy" />
        </Helmet>
      )
    },
    {
      slug: "sales-process-development-brogram",
      id: 21,
      meta: () => (
        <Helmet>
          <title>Sales Process Development Program by Ikana Business Solution</title>
          <meta name="description" content="The client was looking for a team of experts to optimise their sales processes and explore new business opportunities in their target market." />
          <link rel="canonical" href="https://ikana.io/project/sales-process-development-brogram" />
        </Helmet>
      )
    },
    {
      slug: "sales-process-development-ihelp",
      id: 22,
      meta: () => (
        <Helmet>
          <title>Ihelp Sales process Development Program by Ikana Business Solution</title>
          <meta name="description" content="The client was looking for a team of experts to promote and give exposure to their newly launched product in the GCC market." />
          <link rel="canonical" href="https://ikana.io/project/sales-process-development-ihelp" />
        </Helmet>
      )
    },
    {
      slug: "ui-ux-development-strategy-parley",
      id: 12,
      meta: () => (
        <Helmet>
          <title>Parley UI/UX Development Strategy By Ikana Business Solution</title>
          <meta name="description" content="The development of Parley Application was an exciting project for us! We created the UI iteration with the latest design trends to fulfil the client’s vision." />
          <link rel="canonical" href="https://ikana.io/project/ui-ux-development-strategy-parley" />
        </Helmet>
      )
    },
    {
      slug: "ui-ux-development-strategy-homey",
      id: 1,
      meta: () => (
        <Helmet>
          <title>Homey Food delivery App- UI/UX Development Strategy by Ikana</title>
          <meta name="description" content="This project took us on an interesting journey from understanding what the client envisions to delivering a robust UI iteration for the application." />
          <link rel="canonical" href="https://ikana.io/project/ui-ux-development-strategy-homey" />
        </Helmet>
      )
    },
    {
      slug: "ui-ux-development-strategy-replica",
      id: 23,
      meta: () => (
        <Helmet>
          <title>Replica Virtual Currency App- UI/UX Development by Ikana</title>
          <meta name="description" content="We worked with our client on this exciting project and delivered a robust UI iteration of a digital cryptocurrency platform." />
          <link rel="canonical" href="https://ikana.io/project/ui-ux-development-strategy-replica" />
        </Helmet>
      )
    },
    {
      slug: "brand-identity-replica",
      id: 13,
      meta: () => (
        <Helmet>
          <title>Replica Cryptocurrency Brand Identity Strategy by Ikana</title>
          <meta name="description" content="In this project, our team created a complete brand identity for our client. From logo to typography, to theme, and brand guidelines- the whole works." />
          <link rel="canonical" href="https://ikana.io/project/brand-identity-replica" />
        </Helmet>
      )
    },
    {
      slug: "rebranding-strategy-parley",
      id: 14,
      meta: () => (
        <Helmet>
          <title>Parley App Rebranding Strategy By Ikana Business Solution</title>
          <meta name="description" content="With the constant guidance and inputs of our client, we were able to rebrand the mobile application and create a fun new identity." />
          <link rel="canonical" href="https://ikana.io/project/rebranding-strategy-parley" />
        </Helmet>
      )
    },
  ],
  WHERE_TO_FIND_US: "/info",
  PROJECT_ESTIMATION: "/projects/calculator",
  START_PROJECT_ESTIMATION: "/projects/calculator/new",
  FINISH_PROJECT_ESTIMATION: "/projects/calculator/results",

  // ====== SOCIAL LINKS ======
  FACEBOOK: "#",
  LINKED_IN: "https://www.linkedin.com/company/ikana-business-solutions/",
  INSTAGRAM: "https://www.instagram.com/ikana_business_solutions/",
  TWITTER: "#",
  REDDIT: "#",
  BEHANCE: "#",
});
