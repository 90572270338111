import { types } from "mobx-state-tree";
import { withEnvironment } from "../../";

import * as schemas from "./schemas";

export const About = types
  .model("About")
  .props({
    header: types.maybeNull(schemas.HeaderObject),
    subHeader: types.maybeNull(schemas.SubHeaderObject),
    values: types.maybeNull(schemas.ValuesObject),
    team: types.maybeNull(schemas.TeamObject),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    _updateHeader: (data) => {
      self.header = schemas.HeaderObject.create(data);
    },

    _updateSubHeader: (data) => {
      self.subHeader = schemas.SubHeaderObject.create(data);
    },

    _updateValues: (data) => {
      self.values = schemas.ValuesObject.create({
        title: "Values we stand for",
        values: data,
      });
    },

    _updateTeamMembers: (data) => {
      self.team = schemas.TeamObject.create({
        title: "Meet the team!",
        subTitle: "Alone we can do so little; together we can do so much",
        members: data,
      });
    },
  }));
