import { types } from "mobx-state-tree";
import { withEnvironment } from "../../";

import * as schemas from "./schemas";

export const Home = types
  .model("Home")
  .props({
    header: types.maybeNull(schemas.HeaderObject),
    subHeader: types.maybeNull(schemas.SubHeaderObject),
    services: types.maybeNull(schemas.ServicesObject),
    requireServices: types.maybeNull(schemas.RequireServiceObject),
    previousProjects: types.maybeNull(schemas.PreviousProjectObject),
    testimonies: types.maybeNull(schemas.TestimonyObject),
    results: types.maybeNull(schemas.resultCategoryObject),
    selectedServices: types.array(schemas.selectedServicesObject),
    unSelectedServices: types.array(schemas.selectedServicesObject),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    _updateResults: (data) => {
      self.results = schemas.resultCategoryObject.create({ categories: data });
    },
    _updateHeader: (data) => {
      self.header = schemas.HeaderObject.create(data);
    },
    _updateSubHeader: (data) => {
      self.subHeader = schemas.SubHeaderObject.create(data);
    },
    _updateServices: (data) => {
      self.services = schemas.ServicesObject.create({
        title: "Services we provide",
        description: "Here’s what our Business Team can do for you",
        items: data,
      });
    },
    _updateRequiredServices: (data) => {
      self.requireServices = schemas.RequireServiceObject.create({
        title: "Who need our services?",
        description: "How to figure out if you need a Business Team",
        items: data,
      });
    },
    _updatePreviousProjects: (data) => {
      self.previousProjects = schemas.PreviousProjectObject.create({
        title: "Previous Projects",
        description: "See all projects",
        items: data,
      });
    },
    _updateTestimonials: (data) => {
      self.testimonies = schemas.TestimonyObject.create({
        title: "What our clients said",
        description: "Here’s what our clients have to say about us",
        items: data,
      });
    },
    _updateSelectedServices: (data) => {
      data.forEach(element => {
        self.selectedServices.push(element)
      });
    },
    _clearSelectedServices: () => {
      self.selectedServices.clear()
    },
    _updateUnSelectedServices: (data) => {
      data.forEach(element => {
        self.unSelectedServices.push(element)
      });
    },
    _clearUnSelectedServices: () => {
      self.unSelectedServices.clear()
    },
  }));
