import React from "react";
import ReactDOM from "react-dom";

import "animate.css/animate.min.css";
import "./styles/index.css";
import "./styles/utilities.scss";

import reportWebVitals from "./reportWebVitals";
import RootApp from "./RootApp";

ReactDOM.render(<RootApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
