// ====== Header Schema ======
import { types } from "mobx-state-tree";

export const HeaderSchema = {
  title: types.string,
  subTitle: types.string,
};

export const HeaderObject = types.model("HeaderObject").props(HeaderSchema);

//  ====== Sub Header Schema ======
export const SubHeaderSchema = {
  title: types.string,
  description: types.string,
};

export const SubHeaderObject = types
  .model("SubHeaderObject")
  .props(SubHeaderSchema);

//  ====== Values Schema ======

export const ValueItemSchema = {
  image: types.string,
  title: types.string,
  description: types.string,
};

export const ValueItem = types.model("ValueItem").props(ValueItemSchema);

export const ValuesSchema = {
  title: types.string,
  values: types.array(ValueItem),
};

export const ValuesObject = types.model("ValuesObject").props(ValuesSchema);

//  ====== Team Schema ======

export const TeamMemberSchema = {
  image: types.string,
  name: types.string,
  position: types.string,
};

export const TeamMember = types.model("TeamMember").props(TeamMemberSchema);

export const TeamSchema = {
  title: types.string,
  subTitle: types.string,
  members: types.array(TeamMember),
};

export const TeamObject = types.model("TeamObject").props(TeamSchema);
