import { types } from "mobx-state-tree";
import { withEnvironment } from "../../";

import * as schemas from "./schemas";

export const Footer = types
  .model("Footer")
  .props({
    footer: types.maybeNull(schemas.FooterObject),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    _updateData: (data) => {
      self.footer = schemas.FooterObject.create(data);
    },
  }));
