import { types } from "mobx-state-tree";

// ====== Header Schema ======
export const HeaderSchema = {
  title: types.string,
  subTitle: types.string,
  btnTitle: types.string,
};

export const HeaderObject = types.model("HeaderObject").props(HeaderSchema);

//  ====== Sub Header Schema ======
export const SubHeaderSchema = {
  title: types.string,
  description: types.string,
};

export const SubHeaderObject = types
  .model("SubHeaderObject")
  .props(SubHeaderSchema);

//  ====== Services Schema ======

// service projects schema
export const ProjectSchema = {
  image: types.string,
  title: types.string,
  description: types.string,
  year: types.string,
  color: types.string,
};

export const ProjectObject = types.model("ProjectObject").props(ProjectSchema);

// Image slides schema===
export const slidesSchema = {
  image: types.string,
};

export const slidesObject = types.model("slidesObject").props(slidesSchema);

// service processes schema
export const processSchema = {
  id: types.number,
  title: types.string,
  description: types.string,
};

export const processObject = types.model("processObject").props(processSchema);

// SINGLE SERVICE SCHEMA
export const SingleServiceSchema = {
  image: types.string,
  title: types.string,
  description: types.string,
  advantage: types.string,
  disadvantage: types.string,
  projects: types.array(ProjectObject),
  processes: types.array(processObject),
  slides: types.array(slidesObject),
  who_needs_this: types.array(types.string),
  what_you_will_miss: types.array(types.string),
};

export const SingleServiceObject = types
  .model("SingleServiceObject")
  .props(SingleServiceSchema);

// MAIN SERVICE SCHEMA
export const ServicesSchema = {
  title: types.string,
  description: types.string,
  items: types.array(SingleServiceObject),
};

export const ServicesObject = types
  .model("ServicesObject")
  .props(ServicesSchema);

// Require services schema
export const SingleRequireServiceSchema = {
  image: types.string,
  title: types.string,
  description: types.string,
  btn: types.string,
};

export const SingleRequireServiceObject = types
  .model("SingleRequireServiceObject")
  .props(SingleRequireServiceSchema);

export const RequireServicesSchema = {
  title: types.string,
  description: types.string,
  items: types.array(SingleRequireServiceObject),
};

export const RequireServiceObject = types
  .model("RequireServiceObject")
  .props(RequireServicesSchema);

// Previous Projects schema
export const SinglePreviousProjectsSchema = {
  image: types.string,
  title: types.string,
  description: types.string,
  btn: types.string,
  year: types.string,
  type: types.string,
};

export const SinglePreviousProjectObject = types
  .model("SinglePreviousProjectObject")
  .props(SinglePreviousProjectsSchema);

export const PreviousProjectSchema = {
  title: types.string,
  description: types.string,
  items: types.array(SinglePreviousProjectObject),
};

export const PreviousProjectObject = types
  .model("PreviousProjectObject")
  .props(PreviousProjectSchema);

// Testimonial schema
export const SingleTestimonialSchema = {
  image: types.string,
  name: types.string,
  position: types.string,
  testimony: types.string,
};

export const SingleTestimonyObject = types
  .model("SingleTestimonyObject")
  .props(SingleTestimonialSchema);

export const TestimonySchema = {
  title: types.string,
  description: types.string,
  items: types.array(SingleTestimonyObject),
};

export const TestimonyObject = types
  .model("TestimonyObject")
  .props(TestimonySchema);

// CALCULATOR-RESULTS

export const resultCategorySchema = {
  categories: types.array(types.string),
};

export const resultCategoryObject = types
  .model("resultCategoryObject")
  .props(resultCategorySchema);


export const selectedServicesSchema = {
  id: types.number,
  name: types.string,
  selected: types.boolean,
  pricing: types.number,
  home_description: types.maybeNull(types.string),
  image: types.maybeNull(types.string)
};

export const selectedServicesObject = types.model("selectedServicesObject").props(selectedServicesSchema);